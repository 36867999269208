import { Component, OnInit } from '@angular/core';
import { MAIN_MENU } from './home-menu';
import { AppService } from '../services/app.service';
import { jsonpFactory } from '@angular/http/src/http_module';
import * as _ from 'lodash';

@Component({
  selector: 'ngx-home',
  styleUrls: ['home.component.scss'],
  template: `
    <ngx-one-column-layout>
      <nb-menu [items]="menu" autoCollapse="true"></nb-menu>
      <router-outlet></router-outlet>
    </ngx-one-column-layout>
  `,
})

export class HomeComponent implements OnInit {
  private modules;
  constructor(private appService: AppService) { }
  menu = [];
  _allPremissions = []
  ngOnInit() {
    var _finalpremission = []
    this.appService.getData().subscribe(data => {
      if (data) {
        this.menu = data;
      } else {
        var self = this
        self._allPremissions = JSON.parse(localStorage.getItem('_allPremissions'));
        _.each(MAIN_MENU, function (menu) {
          _.each(self._allPremissions, function (allprem) {
            if (menu.title.toLowerCase() == allprem.Title.toLowerCase()) {
              if (menu.children) {
                var objarray = []
                var finalobj = {}
                _.each(menu.children, function (menuchild) {
                  _.each(allprem.children, function (allpremchild:any) {
                    if (menuchild.data.toLowerCase() == allpremchild.slug.toLowerCase()) {
                      objarray.push(menuchild)
                    }
                  })
                })
                if(objarray.length>0){
                  finalobj = {
                    title: menu.title,
                    icon: menu.icon,
                    children: _.uniqBy(objarray,'title')
                  }
                  self.menu.push(finalobj);
                }
               
              } else {
                const objarray = allprem.children.some(premChild => {
                  if (premChild.slug && menu.data) {
                    return premChild.slug.toLowerCase() === menu.data.toLowerCase();
                  }
                  return false;
                });
                let obj2:any;
              if(objarray){
                obj2 = {
                  title: menu.title,
                  icon: menu.icon,
                  link: '/home/' + menu.title.toLowerCase()
                }
                self.menu.push(obj2);
                }
              }
            }
          })

        })
        // if (_finalpremission.length > 0) {
        //   var self = this
        //   _.each(_finalpremission, function (element) {
        //     if (element.children) {
        //       if (element.children.length > 0) {
        //         var finalobj = {
        //           title: element.title,
        //           icon: element.icon,
        //           children: element.children
        //         }
        //         self.menu.push(finalobj);
        //       }
        //     } else {
        //       var obj2 = {
        //         title: element.title,
        //         icon: element.icon,
        //         link: '/home/' + element.title.toLowerCase()
        //       }
        //       self.menu.push(obj2);
        //     }
        //   })
        // }
      }
    });
  }
}