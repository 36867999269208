import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private API_URL = environment.API_URL;

  constructor(private http: HttpClient) { }
  getRewards(): Observable<any> {
    return this.http.get(this.API_URL +'/api/rewards/types');
  }
  login(creds): Observable<any> {
    return this.http.post(this.API_URL + '/api/auth/login', creds);
  }

  getRoles(): Observable<any> {
    return this.http.get(this.API_URL + '/api/listing/rolesdata');
  }
  getIp(): Observable<any> {
    return this.http.get('https://cors-anywhere.herokuapp.com/http://api.ipify.org/?format=json');
  }
}
