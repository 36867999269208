import { Injectable } from '@angular/core';

export class ComponentsList {
    path: string;
    permission: string;
}

const componentsList: ComponentsList[] = [
    {
        path: 'dashboard',
        permission: 'view-dashboard',
    },
    {
        path: 'add-graph',
        permission: 'view-dashboard',
    },
    {
        path: 'add-new-reward',
        permission: 'add-reward',
    }, {
        path: 'add-bonus',
        permission: 'add-bonus',
    },
    {
        path: 'add-budget',
        permission: 'add-budget',
    },
    {
        path: 'new-scheme',
        permission: 'add-scheme',
    },
    {
        path: 'new-sku',
        permission: 'add-scheme',
    },
    {
        path: 'scheme-view',
        permission: 'view-scheme',
    }, {
        path: 'sku-detail',
        permission: 'view-scheme',
    },
    {
        path: 'sku-view',
        permission: 'view-scheme',
    },
    {
        path: 'reward-view',
        permission: 'view-scheme',
    },
    {
        path: 'qc-lists',
        permission: 'view-quality-check',
    },
    {
        path: 'to-be-qualified',
        permission: 'view-quality-check',
    },
    {
        path: 'edit-details',
        permission: 'approve-quality-check',
    },
    {
        path: 'inbound-panel',
        permission: 'connect-call',
    },
    {
        path: 'call-details',
        permission: 'connect-call',
    },
    {
        path: 'outbound',
        permission: 'view-call-list',
    },
    {
        path: 'inbound',
        permission: 'view-call-list',
    },
    {
        path: 'lookup-user',
        permission: 'lookup-user',
    },
    {
        path: 'view-csm',
        permission: 'view-csm',
    },
    {
        path: 'assign-csm',
        permission: 'assign-csm',
    },
    {
        path: 'assign-calls',
        permission: 'create-csm',
    },
    {
        path: 'create-csm',
        permission: 'assign-calls',
    },
    {
        path: 'upload',
        permission: 'create-trade-partner',
    },
    {
        path: 'bank-data-approval',
        permission: 'bank-data-approval',
    },
    {
        path: 'user-registration-approval',
        permission: 'user-registration-approval',
    },
    {
        path: 'image',
        permission: 'user-registration-approval',
    },
];


@Injectable({
    providedIn: 'root',
})
export class ComponentsService {
    getComponentsList(): ComponentsList[] {
        return componentsList;
    }
}
