import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QualityCheckService {
  API_URL = environment.API_URL;
  constructor(private _http: HttpClient) { }
  getQcList(data): Observable<any> {
    return this._http.post(this.API_URL + '/api/bpo/quality-check/list', data);
  }
  
  toBeQualified(data): Observable<any> {
    return this._http.post(this.API_URL + '/api/bpo/quality-check/to-be-qualified', data);
  }
  toBeQualifiedUpdate(data, id): Observable<any> {
    return this._http.post(this.API_URL + `/api/bpo/quality-check/${id}/update`, data)
  }
  getSchemes(): Observable<any> {
    return this._http.get(this.API_URL + `/api/bpo/listing/schemes`);
  }
  nonAssignedCsms(id): Observable<any> {
    return this._http.get(this.API_URL + `/api/bpo/listing/${id}/not-assigned-csms`);
  }
}
