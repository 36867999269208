
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ComponentsService } from './components-list';
import { Router } from '@angular/router';
import * as _ from 'lodash';
@Injectable({
  providedIn: 'root',
})
export class AppService {
  private dataSource = new BehaviorSubject<any>(null);
  private Formsource = new BehaviorSubject<any>(null);
  private rejectIDsource = new BehaviorSubject<any>(null);
  private searchsurce = new BehaviorSubject<any>(null);
  private saveunblocksource = new BehaviorSubject<any>(null);
  private saveDataschemasource = new BehaviorSubject<any>(null);
  private saveskuschemasource = new BehaviorSubject<any>(null);

  private saveDownloadschemasource = new BehaviorSubject<any>(null);
  private SaveRequestId = new BehaviorSubject<any>(null);
  private WinnersId = new BehaviorSubject<any>(null);
  private crystalSchemeData = new BehaviorSubject<any>(null);
  private settingsData = new BehaviorSubject<any>(null);





  data = this.dataSource.asObservable();
  _CrystalScheme = this.crystalSchemeData.asObservable();
  Formdata = this.Formsource.asObservable();
  rejectID = this.rejectIDsource.asObservable();
  searchsurcedata = this.searchsurce.asObservable();
  unblockdata = this.saveunblocksource.asObservable();
  saveschema = this.saveDataschemasource.asObservable();
  saveskunew = this.saveskuschemasource.asObservable();

  saveDownloadsata = this.saveDownloadschemasource.asObservable();
  requestID= this.SaveRequestId.asObservable();
  LuckyWinnersId= this.WinnersId.asObservable();
  SettingsData = this.settingsData.asObservable();



  constructor(private componentService: ComponentsService, private router: Router) { }
  // role data save and get
  private roledata = new BehaviorSubject<any>(null);
  saverolerowdata = this.roledata.asObservable();
  saveroledata(data) {
    this.roledata.next(data);
  }
  saveschemeData(data) {
    console.log(data)
    this.crystalSchemeData.next(data);
  }
  getroledata(): Observable<any> {
    return this.saverolerowdata;
  }
  getcrystlScheme(): Observable<any> {
    return this._CrystalScheme;
  }

  saveSettings(data){
    this.settingsData.next(data);
  }

  getSettings(): Observable<any>{
    return this.SettingsData;
  }

 // assign premission  data save and get
 private assigndata = new BehaviorSubject<any>(null);
 saveassignrowdata = this.assigndata.asObservable();
 saveassignroledata(data) {
   this.assigndata.next(data);
 }
 getassignroledata(): Observable<any> {
   return this.saveassignrowdata;
 }
  //assign premission role data save and get
   // fallowup data save and get
   private fallowup = new BehaviorSubject<any>(null);
   savefallowup = this.fallowup.asObservable();
   savefallowupdata(data) {
     this.fallowup.next(data);
   }
   getfallowupdata(): Observable<any> {
     return this.savefallowup;
   }
    //end fallowup data save and get
     // rewards disbrcement action data save and get
   private _saveAction = new BehaviorSubject<any>(null);
   saveaction = this._saveAction.asObservable();
   saveactiondata(data) {
     this._saveAction.next(data);
   }
   getactionData(): Observable<any> {
     return this.saveaction;
   }
    //end rewards disbrcement action data save and get
  saveData(data) {
    this.dataSource.next(data);
  }
  saveDatalucky(data) {
    this.WinnersId.next(data);
  }
  // saveDownload
  saveDataschema(data) {
    this.saveDataschemasource.next(data);
  }
  savesku(data) {
    this.saveskuschemasource.next(data);
  }
  saveDownload(data) {
    this.saveDownloadschemasource.next(data);
  }
  saverejectID(data) {
    this.rejectIDsource.next(data);
  }
  saveformdata(data) {
    this.Formsource.next(data);
  }
  saveDataunblock(data) {
    this.saveunblocksource.next(data);

  }
  saverequestid(data){
    this.SaveRequestId.next(data);

  }
  saveDatasearch(data) {
    this.searchsurce.next(data);
  }
  getsearchdata(): Observable<any> {
    return this.searchsurcedata;
  }
  getluckydata(): Observable<any> {
    return this.LuckyWinnersId;
  }
  getunblockdata(): Observable<any> {
    return this.unblockdata;
  }
  getData(): Observable<any> {
    return this.data;
  }
  getsku(): Observable<any> {
    return this.saveskunew;
  }
  getrequestid(): Observable<any> {
    return this.requestID;
  }
 getDataschema(): Observable<any> {
    return this.saveschema;
  }
  getId(): Observable<any> {
    return this.rejectID;
  }
  getfoemdata(): Observable<any> {
    return this.Formdata;
  }
  getDownloaddata(): Observable<any> {
    return this.saveDownloadsata;
  }
  isPermitted(currentRoute) {
    const permissions = JSON.parse(localStorage.getItem('getpremissions'));
    if(permissions && permissions.length>0 && localStorage.getItem('_allPremissions')){
      for (let i = 0; i < permissions.length; i++) {
        // tslint:disable-next-line: triple-equals
        if (permissions[i] == currentRoute) {
          return true;
        }
      }
    }else{
      this.router.navigateByUrl('/auth');

    }
    // const currentComponent = this.getComponent(currentRoute);
  
  }
  // getComponent(currentRoute): any {
  //   const componentsList = this.componentService.getComponentsList();
  //   for (let i = 0; i < componentsList.length; i++) {
  //     // tslint:disable-next-line: triple-equals
  //     if (componentsList[i].path == currentRoute) {
  //       return componentsList[i];
  //     }
  //   }
  // }
}

