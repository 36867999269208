import { NbMenuItem } from '@nebular/theme';
export const MAIN_MENU: NbMenuItem[] = [
  {
    title: 'Dashboard',
    icon: 'bar-chart-outline',
    link: '/home/dashboard',
    home: true,
  },
    {
    title: 'Reports',
    // icon: 'clipboard-outline',
    icon: 'pie-chart-outline',
    children: [
      {
        title: 'Electricians Summary',
        link: '/home/electricians-summary-report',
        data:'electricians-summary-report'
      }
    ],
  },
  // {
  //   title: 'Crystal Schemes',
  //   icon: 'clipboard-outline',
  //   children: [
  //     {
  //       title: 'View',
  //       link: '/home/crystal/List',
  //       data:'crystal-schem'
  //     },
  //     {
  //       title: 'Import Sales',
  //       link: '/home/crystal/import',
  //       data:'Import_sales'
  //     },
  //     {
  //       title: 'Redemptions',
  //       link: '/home/crystal/redemptions',
  //       data:'redemptions'
  //     }
  //   ],
  // },
  {
    title: 'Trade Partners',
    icon: 'globe-outline',
    children: [
      {
        title: 'Create',
        link: '/home/trade-partner/create-trade-partner',
        data:'create-trade-partner'
      },  {
        title: 'List',
        link: '/home/trade-partner/list',
        data:'create-trade-partner'
      },
    ],
  },
  {
    title: 'Schemes',
    icon: 'pantone-outline',
    children: [
      {
        title: 'Scheme List',
        link: '/home/schemes/view-scheme',
        data:'view-scheme'
      },
      {
        title: 'Skus List',
        link: '/home/schemes/view-sku',
        data:'view-sku'
      },
      {
        title: 'Rewards List',
        link: '/home/schemes/view-reward',
        data:'view-reward'

      },
    ],
  },
  {
    title: 'Blocklist',
    icon: 'slash-outline',
    children: [
      {
        title: 'List',
        link: '/home/blocklist/view-blocked-users',
        data:'view-blocked-users'
      },
      {
        title: 'Upload',
        link: '/home/blocklist/block-users',
        data:'block-users'
      },
    ],
  },
  {
    title: 'Roles',
    icon: 'people-outline',
    children: [
      {
        title: 'List',
        link: '/home/roleslisting/view-role',
        data:'view-role'
      },
      {
        title: 'Permissions',
        link: '/home/roleslisting/premissioncreate',
        data:'premissioncreate'
      },
    ],
  },
  // {path : 'view-coupons', component : CoupanslogsviewComponent},
  // {path : 'deactivate-coupons', component : CoupansdeactiveComponent},
  // {path : 'activate-coupons', component : CoupansactiveComponent},
  {
    title: 'Coupons',
    icon: 'briefcase-outline',
    children: [
      {
        title: 'Metrics',
        link: '/home/couponslogs/view-coupons',
        data:'view-coupon-metrics'
      },
      {
        title: 'Activate Coupons',
        link: '/home/couponslogs/activate-coupons',
        data:'deactivate-coupons'
      },
      {
        title: 'Deactivate Coupons',
        link: '/home/couponslogs/deactivate-coupons',
        data:'activate-coupons'
      },
    ],
  },
  {
    title: 'Lucky Draw',
    icon: 'bulb-outline',
    children: [
      {
        title: 'List',
        link: '/home/luckydraw/view-schemes',
        data:'view-schemes'
      },
    ],
  },
  {
    title:'Notifications',
    icon: 'bell-outline',
    children:[
      {
        title: 'List',
        link: '/home/notifications/view-notification-report',
        data:'view-notification-report'
      }      
    ],
  },
  {
    title: 'Reward Disbursement',
    //icon: 'cube-outline',
    icon: 'gift-outline',
    children: [
      {
        title: 'Search Rewards',
        link: '/home/rewards/search',
        data:'process-reward'

      },
      {
        title: 'Requests List',
        link: '/home/rewards/view-all-requests',
        data:'view-all-requests'
      },
      {
        title: 'Requests List',
        link: '/home/rewards/view-all-requests',
        data:'user-request-list'
      },
      {
        title: 'Requests List',
        link: '/home/rewards/view-all-requests',
        data:'under-process-requests'
      },
    ],
  },
  {
    title: 'Finolex Samruddhi App',
    icon: 'smartphone-outline',
    children: [
      {
        title: 'Bank Data Approvals',
        link: '/home/bank/bank-data-approval',
        data:'bank-data-approval'
      },
      {
        title: 'User Registration Approvals',
        link: '/home/bank/user-registration-approval',
        data:'user-registration-approval'
      },
    ],
  },


  // {
  //   title: 'Rewards',
  //   icon: 'gift-outline',
  //   // children: [
  //     // {
  //     //   title: 'Rewards List',
  //     //   link: '/home/reward/reward-view',
  //     // },
  //   // ],
  // },
  /* {
    title: 'Roles',
    icon: 'people-outline',
    link: '/home/admin/roles',
    children: [],
  }, */
  {
    title: 'Users',
    icon: 'person-outline',
    link: '/home/admin/users',
    children: [],
  },
  {
    title: 'Products',
    icon: 'npm-outline',
    children: [
      {
        title: 'Skus',
        link: '/home/product-catalog',
        data:'view-product'
      },
      {
        title: 'Product Groups',
        link: '/home/product-catalog/product-groups',
        data:'view-product'
      },
      {
        title: 'Products',
        link: '/home/product-catalog/products',
        data:'view-product'
      },
    ],
  },


  // {
  //   title: 'Redemptions',
  //   icon: 'briefcase-outline',
  //   children: [
  //     {
  //       title: 'View Redemptions',
  //       link: '/home/redemption/view-redemptions',
  //       data:'view-redemption'
  //     },
  //     {
  //       title: 'Redeem',
  //       link: '/home/redemption/create-redemption',
  //       data:'create-redemption'
  //     },
  //   ]
  // },
 
  {
    title: 'BPO',
    icon: 'phone',
    children: [
      {
        title: 'Inbound',
        link: '/home/bpo/inbound',
        data:'view-call-list'
      },
      {
        title: 'Outbound',
        link: '/home/bpo/outbound',
        data:'view-call-list'
      },
      /*  {
         title: 'Coupon Status',
         link: '/home/bpo/coupon-status',
       }, */
      {
        title: 'Look Up User',
        link: '/home/bpo/lookup-user',
        data:'lookup-user'
      },
      {
        title: 'Follow Ups',
        link: '/home/bpo/followup-list',
        data:'view-all-follow-ups'
      }
    ],
  },
  {
    title: 'Quality Check',
    icon: 'checkmark-square-2-outline',
    children: [
      {
        title: 'To Be Qualified',
        link: '/home/quality-check/approve-quality-check',
        data:'approve-quality-check'
      },
      {
        title: 'View Lists',
        link: '/home/quality-check/view-quality-check',
        data:'view-quality-check'
      },
      {
        title: 'Assign QC',
        link: '/home/quality-check/assign-qc',
        data:'assign-qc'
      },
    ],
  },
  {
    title: 'CSM',
    icon: 'person',
    children: [
      {
        title: 'View CSM',
        link: '/home/csm/view-csm',
        data:'view-csm'
      },
      {
        title: 'Assign CSM',
        link: '/home/csm/assign-csm',
        data:'assign-csm'
      },
      {
        title: 'Assign Calls',
        link: '/home/csm/assign-calls',
        data:'assign-calls'
      },
      {
        title: 'Create CSM',
        link: '/home/csm/create-csm',
        data:'create-csm'
      },
    ],
  },

  {
    title: 'Settings',
    icon: 'settings-2-outline',
    children: [
      {
        title: 'Settings',
        link: '/home/settings',
        data:'settings'
      }
    ]
  },

  


];



