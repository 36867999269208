import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(private http: HttpClient) { }
  private API_URL = environment.API_URL;

  saveSettings(payload){
    return this.http.post(`${this.API_URL}/api/settings`,payload,{ observe: 'response' })
  }

  getSettings(user_id){
    return this.http.get(`${this.API_URL}/api/settings`, { params: { user_id: user_id} , observe: 'response' })
  }
}
