import { Component, OnInit, Input, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { QualityCheckService } from '../services/quality-check.service';

@Component({
  selector: 'ngx-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
})
export class CommentComponent implements OnInit {
  @Input() title: any;
  data;
  comment = '';
  status: any;
  constructor(
    private toastrService: NbToastrService,
    protected ref: NbDialogRef<CommentComponent>,
    private qualityCheckService: QualityCheckService) { }
  ngOnInit() {
    this.data = JSON.parse(this.title);
  }
  onSave() {
    if (!this.comment) {
      this.status = (this.comment) ? '' : 'danger';
      this.toastrService.show('', 'Comment is required', { status: 'warning' });
    } else {
      this.ref.close();
      const obj = {
        'qc_status': this.data.action,
        'comment': this.comment,
      };
      this.qualityCheckService.toBeQualifiedUpdate(obj, this.data.row.id).subscribe((data: any) => {
        if (data.status) {
          this.toastrService.show('', data.message, { status: 'success' });
        }
      });
    }
  }
  closeDialog() {
    this.ref.close();
  }
}
